.HomeBlogSec {
    padding-top: 30px;
    .BlogBox {
        padding: 15px;
        border-radius: 10px;
        border: solid 1px #f0f0f0;
        a {
            text-decoration: none;
            color: #000;
            .HomeBlogImg {
                border-radius: 7px;
                overflow: hidden;
                margin-bottom: 15px;
                height: 250px;
                img {
                    height: 100%;
                    object-fit: cover;
                    width: 100%;
                }
            }
            .HomeBlogCont {
                h4 {
                    font-size: 18px;
                    font-weight: 600;
                    color: #000;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                p {
                    font-size: 15px;
                    margin-bottom: 5px;
                    color: #000000;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;  
                    overflow: hidden;
                }
                .BlogDateTime {
                    font-size: 13px;
                    color: #4b4b4b;
                    margin-bottom: 0;
                }
            }
        }

    }
    .ViewAll {  
        text-align: center;
        margin-top: 20px;
        display: flex;
        justify-content: center;
    }
}